var app = {
  init: function() {
    console.log('init');
  }
};

$(app.init);

var scroll ={
  init: function(){
    $('a[href^="#"]').click(function(){
      var the_id = $(this).attr("href");
      if (the_id === '#') {
        return;
      }
      $('html, body').animate({
        scrollTop:$(the_id).offset().top
      }, 1200);
      return false;
    });
  }
};

$(scroll.init);

var burger ={
  init: function(){
    $(document).ready(function () {

      $('.first-button').on('click', function () {
    
        $('.animated-icon1').toggleClass('open');
      });


/*Remet le icon burger a ça possition intial*/ 
      $('.nav-link').on('click', function () {
    
        $('.animated-icon1').toggleClass('open');
      });

/* ferme le menu au click*/
      $('.nav-link').on('click', function () {
    
        $('.navbar-collapse').toggleClass('show');
      });



      $('.second-button').on('click', function () {
    
        $('.animated-icon2').toggleClass('open');
      });
      $('.third-button').on('click', function () {
    
        $('.animated-icon3').toggleClass('open');
      });
    });
  }
};

$(burger.init)